import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

const winnersList = document.querySelectorAll('.winners-list')

winnersList.forEach((list) => {
	new SimpleBar(list, {
		autoHide: false,
		forceVisible: 'y',
		scrollbarMinSize: 18,
		scrollbarMaxSize: 18,
	});
});