const checkBillType = (element) => {
	let checked = $(element).prop('checked');
	if (checked) {
		$('#personal_data_billType').attr('value', 'Faktura');
        $('#personal_data_billPos').closest('.form-group').css('opacity', 0);
	} else {
		$('#personal_data_billType').attr('value', 'Paragon');
        $('#personal_data_billPos').closest('.form-group').css('opacity', 1);
	}
	$('#personal_data_billPos').prop('disabled', checked);
	$('#personal_data_billPos').prop('required', !checked);
	$('#personal_data_billPos').val('');
};
checkBillType('#personal_data_billTypeCheckbox');

$('#personal_data_billTypeCheckbox').on('change', function() {
	checkBillType(this);
});

document.addEventListener('DOMContentLoaded', function () {	
	$('#personal_data_pesel_checkbox').on('change', function() {
		let checked = $(this).prop('checked');
		$('#personal_data_pesel').prop('disabled', checked);
		$('#personal_data_pesel').prop('required', !checked);
		$('#personal_data_pesel').val('');
		$('fieldset#fieldset-pesel').toggleClass('d-none').prop('disabled', !checked);
	});
});
