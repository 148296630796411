import './forms'
import './form-application'
import './form-files'
import './calendar'
import './text-counter'
import './simplebar'
import './swiper/swiper'
import './navigation'
import './svg-inject'
import './read-smore'
import * as bootstrap from "bootstrap5";

$(function () {
    let lastScrollTop = 0;
    let deg = 0;

    $('.logo-background').animate(
        { deg: 360 },
        {
            duration: 10000,
            step: function(now) {
                $(this).css({ transform: 'rotate(' + now + 'deg)' });
            }
        }
    );

    $(window).scroll(function(event) {
        let st = $(this).scrollTop();
        if (st > lastScrollTop){
            deg += 1;
        } else {
            deg -= 1;
        }

        $('.logo-background').css('transform', `rotate(${deg}deg)`);

        lastScrollTop = st;
    });

    $('#buyButton').on('click', function () {
        $(this).addClass('d-none');
        $('#buyButtons').removeClass('d-none');
    })
});