import ReadSmore from 'read-smore';

// target all read more elements
const readMores = document.querySelectorAll('.js-read-smore');
const options = {
	moreText: 'więcej »',
	lessText: 'mniej «'
}

// Init
ReadSmore(readMores, options).init();

// Prevent the read more/less link from checking the box
const readMoreElements = document.querySelectorAll('a.read-smore__link');

if (readMoreElements) {
	readMoreElements.forEach((element) => {
		element.addEventListener('click', (event) => {
			event.preventDefault();
		});
	});
}