import Swiper, { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export const swiperWinners = new Swiper('.swiper-winners-dates .swiper', {
	modules: [Navigation],
	loop: false,
	allowTouchMove: false,
	autoHeight: true,
	navigation: {
		nextEl: '.swiper-winners-dates .btn-swiper-navigation-next',
		prevEl: '.swiper-winners-dates .btn-swiper-navigation-prev',
	},
});

export const swiperWinnersList = new Swiper('.swiper-winners-lists .swiper', {
	modules: [Navigation],
	loop: false,
	allowTouchMove: false,
	autoHeight: true,
	navigation: {
		nextEl: '.swiper-winners-dates .btn-swiper-navigation-next',
		prevEl: '.swiper-winners-dates .btn-swiper-navigation-prev',
	},
});
